.trapmap-marker-holder {
    margin-left: 0 !important;
    margin-top: 0 !important;
    width: 0 !important;
    height: 0 !important;
    position: relative !important;
}

.trapmap-marker-background {
    position: absolute;
    width: 44px;
    height: 44px;
    left: -24px;
    top: -24px;
}

.trapmap-marker-icon {
    position: absolute;
    left: -12px;
    top: -12px;
    font-size: 0;
}

.trapmap-marker-icon.not-interactive {
    cursor: crosshair;
}

.trapmap-marker-selected {
    border: 2px solid #393920;
    border-radius: 12px;
    background-color: rgba(41, 41, 32, 0.25);
    margin-left: -2px;
    margin-top: -2px;
}

.highlighted .trapmap-marker-selected {
    border: 2px solid #fe5a11;
    background-color: rgba(254, 90, 17, 0.25);
}

.trapmap-marker-label {
    display: inline-block;
    white-space: nowrap;
    font-family: Roboto;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 6px black, 0 0 5px black, 0 0 4px black;
}

.trapmap-label {
    display: inline-block;
    white-space: nowrap;
    font-family: Roboto;
    font-weight: bold;
    text-shadow: 0 0 10px white, 0 0 8px white, 0 0 7px white, 0 0 6px white, 0 0 5px white, 0 0 4px white;
    transform: translate(-50%,-50%);
}
