body {
    margin: 0;
    background: center / cover no-repeat url(img/topomap.svg), #908873;
    background-attachment: fixed;
    height: 100vh;
    overflow-x: hidden;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
		 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		 sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* fix to react-color-palette */
.rcp-body {
    width: 100%;
}

/* fix to leaflet elements not losing cursor setting when changed to noninteractive */
.leaflet-interactive.disabled {
    cursor: inherit !important;
}

.crosshair-cursor {
    cursor: crosshair !important;
}

.wait-cursor {
    cursor: wait !important;
}

.leaflet-marker-pane {
    z-index: 999 !important;
}

.leaflet-overlay-pane {
    z-index: 998 !important;
}
