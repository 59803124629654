a {
    color: #393920;
}


.forward-transition-enter {
    opacity: 0;
    -webkit-transform: translateX(400px);
    -moz-transform: translateX(400px);
    -o-transform: translateX(400px);
    transform: translateX(400px);
}

.forward-transition-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: opacity 300ms, transform 300ms ease-out;
    -moz-transition: opacity 300ms, transform 300ms ease-out;
    -o-transition: opacity 300ms, transform 300ms ease-out;
    transition: opacity 300ms, transform 300ms ease-out;
}

.forward-transition-exit {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.forward-transition-exit-active {
    opacity: 0;
    -webkit-transform: translateX(-700px);
    -moz-transform: translateX(-700px);
    -o-transform: translateX(-700px);
    transform: translateX(-700px);
    -webkit-transition: opacity 300ms, transform 300ms ease-in;
    -moz-transition: opacity 300ms, transform 300ms ease-in;
    -o-transition: opacity 300ms, transform 300ms ease-in;
    transition: opacity 300ms, transform 300ms ease-in;
}


.backward-transition-enter {
    opacity: 0;
    -webkit-transform: translateX(-400px);
    -moz-transform: translateX(-400px);
    -o-transform: translateX(-400px);
    transform: translateX(-400px);
}

.backward-transition-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: opacity 300ms, transform 300ms ease-out;
    -moz-transition: opacity 300ms, transform 300ms ease-out;
    -o-transition: opacity 300ms, transform 300ms ease-out;
    transition: opacity 300ms, transform 300ms ease-out;
}

.backward-transition-exit {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.backward-transition-exit-active {
    opacity: 0;
    -webkit-transform: translateX(700px);
    -moz-transform: translateX(700px);
    -o-transform: translateX(700px);
    transform: translateX(700px);
    -webkit-transition: opacity 300ms, transform 300ms ease-in;
    -moz-transition: opacity 300ms, transform 300ms ease-in;
    -o-transition: opacity 300ms, transform 300ms ease-in;
    transition: opacity 300ms, transform 300ms ease-in;
}


.neutral-transition-enter {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}

.neutral-transition-enter-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity 300ms, transform 300ms ease-out;
    -moz-transition: opacity 300ms, transform 300ms ease-out;
    -o-transition: opacity 300ms, transform 300ms ease-out;
    transition: opacity 300ms, transform 300ms ease-out;
}

.neutral-transition-exit {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.neutral-transition-exit-active {
    opacity: 0;
    -webkit-transform: translateY(70px);
    -moz-transform: translateY(70px);
    -o-transform: translateY(70px);
    transform: translateY(70px);
    -webkit-transition: opacity 300ms, transform 300ms ease-in;
    -moz-transition: opacity 300ms, transform 300ms ease-in;
    -o-transition: opacity 300ms, transform 300ms ease-in;
    transition: opacity 300ms, transform 300ms ease-in;
}


.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    transition: opacity 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    transition: opacity 300ms;
}
